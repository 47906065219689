<template>
  <div class="announcement">
    <div class="title">公告</div>
    <div class="list">
      <div
        class="item"
        @click="toPush(item.id)"
        v-for="item in listData.list"
        :key="item.id"
      >
        <svg
          t="1650790029133"
          style="margin-right: 10px; margin-top: 2px"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="1726"
          width="18"
          height="18"
        >
          <path
            d="M768 332.8l-76.8 76.8c0 0 0-307.2-256-409.6 0 0-25.6 281.6-153.6 384s-384 409.6 128 640c0 0-256-281.6 76.8-486.4 0 0-25.6 102.4 102.4 204.8s0 281.6 0 281.6S1203.2 870.4 768 332.8z"
            p-id="1727"
            fill="#EE4C2D"
          ></path>
        </svg>
        <div>
          <div class="name">{{ item.title }}</div>
          <div class="contenr">{{ item.description }}</div>
          <div style="font-size: 14px">
            <span style="color: #ee4d2d">新</span>
            <span style="color: #999999"> · {{ item.time | formatTime('all') }}</span>
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex; justify-content: right; margin-top: 20px">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-size="pageSize"
        layout=" prev, pager, next, jumper"
        :total="listData.total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getArticleList, getArticleInfo } from "@/api/shop.js";
export default {
  data() {
    return {
      listData: "",
      currentPage4: 1,
      pageSize: 10,
    };
  },
  created() {
    this.getList();
  },

  methods: {
    getList() {
      getArticleList({
        page: this.currentPage4,
        page_size: this.pageSize,
      }).then((res) => {
        if (res.code == 1) {
          this.listData = res.data;
          // this.listData.list.push(res.data.list[0])
        }
      });
    },
    getInfo(id) {
      getArticleInfo({
        id: id,
      }).then((res) => {
        if (res.code == 1) {
          localStorage.ARTICLEINFO = JSON.stringify(res.data);
          this.$router.push("announcementInfo");
        }
      });
    },
    toPush(id) {
      this.getInfo(id);
    },
    handleCurrentChange(page) {
      this.currentPage4 = page;
      this.getList();
    },
  },
};
</script>
<style lang="less" scoped>
.announcement {
  width: 80%;
  margin: 0 auto;
  background: #fff;
  padding: 30px;
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  .title {
    font-size: 22px;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 30px;
  }
  .list {
    // margin-top: 24px;
    .item {
      display: flex;
      border-bottom: 1px solid #e5e5e5;
      padding: 20px;
      .name {
        font-size: 18px;
      }
      .contenr {
        font-size: 14px;
        margin: 6px 0;
      }
    }
  }
}
</style>